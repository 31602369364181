import {createSlice} from "@reduxjs/toolkit";

const initialReferralsState = {
  isLoading: false,
  notification: null,
  data: null
};

export default createSlice({
  name: "referrals",
  initialState: initialReferralsState,
  reducers: {
    // Referrals Response Notification
    setNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },
    
    // Referrals Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },
    
    /*****
     *  Referrals Reducers
     */

    // fetchReferrals
    referralsFetched: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },    

  }
});