import {createSlice} from "@reduxjs/toolkit";
import { getTransactionStatus } from "app/common/util/Helpers";

const initialInventoryState = {
  isLoading: false,
  notification: null,
  products: null
};

export default createSlice({
  name: "inventory",
  initialState: initialInventoryState,
  reducers: {
    // Inventory Response Notification
    setNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },
    
    // Inventory Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },
    
    /*****
     *  Inventory Reducers
     */

    // fetchProducts
    productsFetched: (state, action) => {
      state.isLoading = false;
      state.products = action.payload?.map(item => ({...item, status: getTransactionStatus(item)}));
    },    

  }
});