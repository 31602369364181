import {createSlice} from "@reduxjs/toolkit";

const initialSettingsState = {
  isLoading: false,
  notification: null,
  profile: null,
  business: null,
  businesses: null,
  webhooks: null
};

export default createSlice({
  name: "settings",
  initialState: initialSettingsState,
  reducers: {
    // Settings Response Notification
    setNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },
    
    // Settings Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },
    
    /*****
     *  Settings Reducers
     */

    // changeActiveBusiness
    setBusiness: (state, action) => {
      state.business = action.payload || {};
    },

    // fetchProfile
    profileFetched: (state, action) => {
      state.isLoading = false; 
      state.profile = action.payload || {};
    },

    // fetchBusiness
    businessFetched: (state, action) => {
      state.isLoading = false; 
      state.business = action.payload?.find(item => [localStorage.getItem("testBusinessId"), localStorage.getItem("liveBusinessId")].includes(`${item.id}`)) || action.payload?.[0] || {};
      state.businesses = action.payload || [];
    },

    // fetchWebhooks
    webhooksFetched: (state, action) => {
      state.isLoading = false; 
      state.webhooks = action.payload || [];
    },

  }
});