export const allCountries = [
    {"name": "Afghanistan", "dialCode": "+93", "code": "AF", "iso": "AFG", "currency": "AFN"},
    {"name": "Albania", "dialCode": "+355", "code": "AL", "iso": "ALB", "currency": "ALL"},
    {"name": "Algeria", "dialCode": "+213", "code": "DZ", "iso": "DZA", "currency": "DZD"},
    {"name": "Andorra", "dialCode": "+376", "code": "AD", "iso": "AND", "currency": "EUR"},
    {"name": "Angola", "dialCode": "+244", "code": "AO", "iso": "AGO", "currency": "AOA"},
    {"name": "Argentina", "dialCode": "+54", "code": "AR", "iso": "ARG", "currency": "ARS"},
    {"name": "Armenia", "dialCode": "+374", "code": "AM", "iso": "ARM", "currency": "AMD"},
    {"name": "Aruba", "dialCode": "+297", "code": "AW", "iso": "ABW", "currency": "AWG"},
    {"name": "Australia", "dialCode": "+61", "code": "AU", "iso": "AUS", "currency": "AUD"},
    {"name": "Austria", "dialCode": "+43", "code": "AT", "iso": "AUT", "currency": "EUR"},
    {"name": "Azerbaijan", "dialCode": "+994", "code": "AZ", "iso": "AZE", "currency": "AZN"},
    {"name": "United States", "dialCode": "+1", "code": "US", "iso": "USA", "currency": "USD"},
    {"name": "Anguilla", "dialCode": "+1", "code": "AI", "iso": "AIA", "currency": "XCD"},
    {"name": "Antigua and Barbuda", "dialCode": "+1", "code": "AG", "iso": "ATG", "currency": "XCD"},
    {"name": "American Samoa", "dialCode": "+1", "code": "AS", "iso": "ASM", "currency": "USD"},
    {"name": "Bahamas", "dialCode": "+1", "code": "BS", "iso": "BHS", "currency": "BSD"},
    {"name": "Bahrain", "dialCode": "+973", "code": "BH", "iso": "BHR", "currency": "BHD"},
    {"name": "Bangladesh", "dialCode": "+880", "code": "BD", "iso": "BGD", "currency": "BDT"},
    {"name": "Barbados", "dialCode": "+1", "code": "BB", "iso": "BRB", "currency": "BBD"},
    {"name": "Belarus", "dialCode": "+375", "code": "BY", "iso": "BLR", "currency": "BYN"},
    {"name": "Belgium", "dialCode": "+32", "code": "BE", "iso": "BEL", "currency": "EUR"},
    {"name": "Belize", "dialCode": "+501", "code": "BZ", "iso": "BLZ", "currency": "BZD"},
    {"name": "Benin", "dialCode": "+229", "code": "BJ", "iso": "BEN", "currency": "XOF"},
    {"name": "Bermuda", "dialCode": "+1", "code": "BM", "iso": "BMU", "currency": "BMD"},
    {"name": "Bhutan", "dialCode": "+975", "code": "BT", "iso": "BTN", "currency": "BTN"},
    {"name": "Bolivia", "dialCode": "+591", "code": "BO", "iso": "BOL", "currency": "BOB"},
    {"name": "Bonaire, Sint Eustatius and Saba", "dialCode": "+599", "code": "BQ", "iso": "BES", "currency": "USD"},
    {"name": "Bosnia and Herzegovina", "dialCode": "+387", "code": "BA", "iso": "BIH", "currency": "BAM"},
    {"name": "Botswana", "dialCode": "+267", "code": "BW", "iso": "BWA", "currency": "BWP"},
    {"name": "Brazil", "dialCode": "+55", "code": "BR", "iso": "BRA", "currency": "BRL"},
    {"name": "British Indian Ocean Territory", "dialCode": "+246", "code": "IO", "iso": "IOT", "currency": "GBP"},
    {"name": "British Virgin Islands", "dialCode": "+1", "code": "VG", "iso": "VGB", "currency": "USD"},
    {"name": "Brunei Darussalam", "dialCode": "+673", "code": "BN", "iso": "BRN", "currency": "BND"},
    {"name": "Bulgaria", "dialCode": "+359", "code": "BG", "iso": "BGR", "currency": "BGN"},
    {"name": "Burkina Faso", "dialCode": "+226", "code": "BF", "iso": "BFA", "currency": "XOF"},
    {"name": "Myanmar", "dialCode": "+95", "code": "MM", "iso": "MMR", "currency": "MMK"},
    {"name": "Burundi", "dialCode": "+257", "code": "BI", "iso": "BDI", "currency": "BIF"},
    {"name": "Cambodia", "dialCode": "+855", "code": "KH", "iso": "KHM", "currency": "KHR"},
    {"name": "Cameroon", "dialCode": "+237", "code": "CM", "iso": "CMR", "currency": "XOF"},
    {"name": "Canada", "dialCode": "+1", "code": "CA", "iso": "CAN", "currency": "CAD"},
    {"name": "Cape Verde", "dialCode": "+238", "code": "CV", "iso": "CPV", "currency": "CVE"},
    {"name": "Cayman Islands", "dialCode": "+1", "code": "KY", "iso": "CYM", "currency": "KYD"},
    {"name": "Central African Republic", "dialCode": "+236", "code": "CF", "iso": "CAF", "currency": "XAF"},
    {"name": "Chad", "dialCode": "+235", "code": "ID", "iso": "TCD", "currency": "XAF"},
    {"name": "Chile", "dialCode": "+56", "code": "CL", "iso": "CHL", "currency": "CLP"},
    {"name": "China", "dialCode": "+86", "code": "CN", "iso": "CHN", "currency": "CNY"},
    {"name": "Colombia", "dialCode": "+57", "code": "CO", "iso": "COL", "currency": "COP"},
    {"name": "Comoros", "dialCode": "+269", "code": "KM", "iso": "COM", "currency": "KMF"},
    {"name": "Cook Islands", "dialCode": "+682", "code": "CK", "iso": "COK", "currency": "NZD"},
    {"name": "Costa Rica", "dialCode": "+506", "code": "CR", "iso": "CRI", "currency": "CRC"},
    {"name": "Cote dIvoire", "dialCode": "+225", "code": "CI", "iso": "CIV", "currency": "XOF"},
    {"name": "Croatia", "dialCode": "+385", "code": "HR", "iso": "HRV", "currency": "HRK"},
    {"name": "Cuba", "dialCode": "+53", "code": "CU", "iso": "CUB", "currency": "CUP"},
    {"name": "Curacao", "dialCode": "+599", "code": "CW", "iso": "CUW", "currency": "ANG"},
    {"name": "Cyprus", "dialCode": "+357", "code": "CY", "iso": "CYP", "currency": "EUR"},
    {"name": "Czechia", "dialCode": "+420", "code": "CZ", "iso": "CZE", "currency": "CZK"},
    {"name": "Denmark", "dialCode": "+45", "code": "DK", "iso": "DNK", "currency": "DKK"},
    {"name": "Djibouti", "dialCode": "+253", "code": "DJ", "iso": "DJI", "currency": "DJF"},
    {"name": "Dominica", "dialCode": "+1", "code": "DM", "iso": "DMA", "currency": "XCD"},
    {"name": "Dominican Republic", "dialCode": "+1", "code": "DO", "iso": "DOM", "currency": "DOP"},
    {"name": "Ecuador", "dialCode": "+593", "code": "EC", "iso": "ECU", "currency": "USD"},
    {"name": "Egypt", "dialCode": "+20", "code": "EG", "iso": "EGY", "currency": "EGP"},
    {"name": "El Salvador", "dialCode": "+503", "code": "SV", "iso": "SLV", "currency": "BTC"},
    {"name": "Equatorial Guinea", "dialCode": "+240", "code": "GQ", "iso": "GNQ", "currency": "XAF"},
    {"name": "Eritrea", "dialCode": "+291", "code": "ER", "iso": "ERI", "currency": "ERN"},
    {"name": "Estonia", "dialCode": "+372", "code": "EE", "iso": "EST", "currency": "EUR"},
    {"name": "Ethiopia", "dialCode": "+251", "code": "ET", "iso": "ETH", "currency": "ETB"},
    {"name": "Falkland Islands", "dialCode": "+500", "code": "FK", "iso": "FLK", "currency": "FKP"},
    {"name": "Faroe Islands", "dialCode": "+298", "code": "FO", "iso": "FRO", "currency": "DKK"},
    {"name": "Federated States of Micronesia", "dialCode": "+691", "code": "FM", "iso": "FSM", "currency": "USD"},
    {"name": "Fiji", "dialCode": "+679", "code": "FJ", "iso": "FJI", "currency": "FJD"},
    {"name": "Finland", "dialCode": "+358", "code": "FI", "iso": "FIN", "currency": "EUR"},
    {"name": "France", "dialCode": "+33", "code": "FR", "iso": "FRA", "currency": "EUR"},
    {"name": "French Guiana", "dialCode": "+594", "code": "GF", "iso": "GUF", "currency": "EUR"},
    {"name": "French Polynesia", "dialCode": "+689", "code": "PF", "iso": "PYF", "currency": "EUR"},
    {"name": "Gabon", "dialCode": "+241", "code": "GA", "iso": "GAB", "currency": "XAF"},
    {"name": "Gambia", "dialCode": "+220", "code": "GM", "iso": "GMB", "currency": "GMD"},
    {"name": "Georgia", "dialCode": "+995", "code": "GE", "iso": "GEO", "currency": "GEL"},
    {"name": "Germany", "dialCode": "+49", "code": "DE", "iso": "DEU", "currency": "EUR"},
    {"name": "Ghana", "dialCode": "+233", "code": "GH", "iso": "GHA", "currency": "GHS"},
    {"name": "Gibraltar", "dialCode": "+350", "code": "GI", "iso": "GIB", "currency": "GIP"},
    {"name": "Greece", "dialCode": "+30", "code": "GR", "iso": "GRC", "currency": "EUR"},
    {"name": "Greenland", "dialCode": "+299", "code": "GL", "iso": "GRL", "currency": "DKK"},
    {"name": "Grenada", "dialCode": "+1", "code": "GD", "iso": "GRD", "currency": "XCD"},
    {"name": "Guadeloupe", "dialCode": "+590", "code": "GP", "iso": "GLP", "currency": "EUR"},
    {"name": "Guam", "dialCode": "+1", "code": "GU", "iso": "GUM", "currency": "USD"},
    {"name": "Guatemala", "dialCode": "+502", "code": "GT", "iso": "GTM", "currency": "GTQ"},
    {"name": "Guinea", "dialCode": "+224", "code": "GN", "iso": "GIN", "currency": "GNF"},
    {"name": "Guinea-Bissau", "dialCode": "+245", "code": "GW", "iso": "GNB", "currency": "XOF"},
    {"name": "Guyana", "dialCode": "+592", "code": "GY", "iso": "GUY", "currency": "GYD"},
    {"name": "Haiti", "dialCode": "+509", "code": "HT", "iso": "HTI", "currency": "HTG"},
    {"name": "Honduras", "dialCode": "+504", "code": "HN", "iso": "HND", "currency": "HNL"},
    {"name": "Hong Kong", "dialCode": "+852", "code": "HK", "iso": "HKG", "currency": "HKD"},
    {"name": "Hungary", "dialCode": "+36", "code": "HU", "iso": "HUN", "currency": "HUF"},
    {"name": "Iceland", "dialCode": "+354", "code": "IS", "iso": "ISL", "currency": "ISK"},
    {"name": "India", "dialCode": "+91", "code": "IN", "iso": "IND", "currency": "INR"},
    {"name": "Indonesia", "dialCode": "+62", "code": "ID", "iso": "IDN", "currency": "IDR"},
    {"name": "Iran", "dialCode": "+98", "code": "IR", "iso": "IRN", "currency": "IRR"},
    {"name": "Iraq", "dialCode": "+964", "code": "IQ", "iso": "IRQ", "currency": "IQD"},
    {"name": "Ireland", "dialCode": "+353", "code": "IE", "iso": "IRL", "currency": "GBP"},
    {"name": "Israel", "dialCode": "+972", "code": "IL", "iso": "ISR", "currency": "ILS"},
    {"name": "Italy", "dialCode": "+39", "code": "IT", "iso": "ITA", "currency": "EUR"},
    {"name": "Jamaica", "dialCode": "+1", "code": "JM", "iso": "JAM", "currency": "JMD"},
    {"name": "Japan", "dialCode": "+81", "code": "JP", "iso": "JPN", "currency": "JPY"},
    {"name": "Jordan", "dialCode": "+962", "code": "JO", "iso": "JOR", "currency": "JOD"},
    {"name": "Russia", "dialCode": "+7", "code": "RU", "iso": "RUS", "currency": "RUB"},
    {"name": "Kazakhstan", "dialCode": "+7", "code": "KZ", "iso": "KAZ", "currency": "KZT"},
    {"name": "Kenya", "dialCode": "+254", "code": "KE", "iso": "KEN", "currency": "KES"},
    {"name": "Kiribati", "dialCode": "+686", "code": "KI", "iso": "KIR", "currency": "AUD"},
    {"name": "Kuwait", "dialCode": "+965", "code": "KW", "iso": "KWT", "currency": "KWD"},
    {"name": "Kyrgyzstan", "dialCode": "+996", "code": "KG", "iso": "KGZ", "currency": "KGS"},
    {"name": "Laos", "dialCode": "+856", "code": "LA", "iso": "LAO", "currency": "LAK"},
    {"name": "Latvia", "dialCode": "+371", "code": "LV", "iso": "LVA", "currency": "EUR"},
    {"name": "Lebanon", "dialCode": "+961", "code": "LB", "iso": "LBN", "currency": "LBP"},
    {"name": "Lesotho", "dialCode": "+266", "code": "LS", "iso": "LSO", "currency": "ZAR"},
    {"name": "Liberia", "dialCode": "+231", "code": "LR", "iso": "LBR", "currency": "LRD"},
    {"name": "Libya", "dialCode": "+218", "code": "LY", "iso": "LBY", "currency": "LYD"},
    {"name": "Liechtenstein", "dialCode": "+423", "code": "LI", "iso": "LIE", "currency": "CHF"},
    {"name": "Lithuania", "dialCode": "+370", "code": "LT", "iso": "LTU", "currency": "EUR"},
    {"name": "Luxembourg", "dialCode": "+352", "code": "LU", "iso": "LUX", "currency": "EUR"},
    {"name": "Macao", "dialCode": "+853", "code": "MO", "iso": "MAC", "currency": "MOP"},
    {"name": "Macedonia", "dialCode": "+389", "code": "MK", "iso": "MKD", "currency": "MKD"},
    {"name": "Madagascar", "dialCode": "+261", "code": "MG", "iso": "MDG", "currency": "MGA"},
    {"name": "Malawi", "dialCode": "+265", "code": "MW", "iso": "MWI", "currency": "MWK"},
    {"name": "Malaysia", "dialCode": "+60", "code": "MY", "iso": "MYS", "currency": "MYR"},
    {"name": "Maldives", "dialCode": "+960", "code": "MV", "iso": "MDV", "currency": "MVR"},
    {"name": "Mali", "dialCode": "+223", "code": "ML", "iso": "MLI", "currency": "XOF"},
    {"name": "Malta", "dialCode": "+356", "code": "MT", "iso": "MLT", "currency": "EUR"},
    {"name": "Marshall Islands", "dialCode": "+692", "code": "MH", "iso": "MHL", "currency": "USD"},
    {"name": "Martinique", "dialCode": "+596", "code": "MQ", "iso": "MTQ", "currency": "EUR"},
    {"name": "Mauritania", "dialCode": "+222", "code": "MR", "iso": "MRT", "currency": "MRU"},
    {"name": "Mauritius", "dialCode": "+230", "code": "MU", "iso": "MUS", "currency": "MUR"},
    {"name": "Mayotte", "dialCode": "+262", "code": "YT", "iso": "MYT", "currency": "EUR"},
    {"name": "Mexico", "dialCode": "+52", "code": "MX", "iso": "MEX", "currency": "MXN"},
    {"name": "Moldova", "dialCode": "+373", "code": "MD", "iso": "MDA", "currency": "MDL"},
    {"name": "Monaco", "dialCode": "+377", "code": "MC", "iso": "MCO", "currency": "EUR"},
    {"name": "Mongolia", "dialCode": "+976", "code": "MN", "iso": "MNG", "currency": "MNT"},
    {"name": "Montenegro", "dialCode": "+382", "code": "ME", "iso": "MNE", "currency": "EUR"},
    {"name": "Montserrat", "dialCode": "+1", "code": "MS", "iso": "MSR", "currency": "XCD"},
    {"name": "Morocco", "dialCode": "+212", "code": "MA", "iso": "MAR", "currency": "MAD"},
    {"name": "Mozambique", "dialCode": "+258", "code": "MZ", "iso": "MOZ", "currency": "MZN"},
    {"name": "Namibia", "dialCode": "+264", "code": "NA", "iso": "NAM", "currency": "NAD"},
    {"name": "Nauru", "dialCode": "+674", "code": "NR", "iso": "NRU", "currency": "AUD"},
    {"name": "Nepal", "dialCode": "+977", "code": "NP", "iso": "NPL", "currency": "NPR"},
    {"name": "Netherlands", "dialCode": "+31", "code": "NL", "iso": "NLD", "currency": "EUR"},
    {"name": "Netherlands Antilles", "dialCode": "+599", "code": "AN", "iso": "ANT", "currency": ""},
    {"name": "New Caledonia", "dialCode": "+687", "code": "NC", "iso": "NCL", "currency": "EUR"},
    {"name": "New Zealand", "dialCode": "+64", "code": "NZ", "iso": "NZL", "currency": "NZD"},
    {"name": "Nicaragua", "dialCode": "+505", "code": "NI", "iso": "NIC", "currency": "NIO"},
    {"name": "Niger", "dialCode": "+227", "code": "NE", "iso": "NER", "currency": "XOF"},
    {"name": "Nigeria", "dialCode": "+234", "code": "NG", "iso": "NGA", "currency": "NGN"},
    {"name": "Niue", "dialCode": "+683", "code": "NU", "iso": "NIU", "currency": "NZD"},
    {"name": "Norfolk Island", "dialCode": "+672", "code": "NF", "iso": "NFK", "currency": "AUD"},
    {"name": "North Korea", "dialCode": "+850", "code": "KP", "iso": "PRK", "currency": "KPW"},
    {"name": "Northern Mariana Islands", "dialCode": "+1", "code": "MP", "iso": "MNP", "currency": "USD"},
    {"name": "Norway", "dialCode": "+47", "code": "NO", "iso": "NOR", "currency": "NOK"},
    {"name": "Oman", "dialCode": "+968", "code": "OM", "iso": "OMN", "currency": "OMR"},
    {"name": "Pakistan", "dialCode": "+92", "code": "PK", "iso": "PAK", "currency": "PKR"},
    {"name": "Palau", "dialCode": "+680", "code": "PW", "iso": "PLW", "currency": "USD"},
    {"name": "Palestine", "dialCode": "+970", "code": "PS", "iso": "PSE", "currency": "EGP"},
    {"name": "Panama", "dialCode": "+507", "code": "PA", "iso": "PAN", "currency": "PAB"},
    {"name": "Papua New Guinea", "dialCode": "+675", "code": "PG", "iso": "PNG", "currency": "PGK"},
    {"name": "Paraguay", "dialCode": "+595", "code": "PY", "iso": "PRY", "currency": "PYG"},
    {"name": "Peru", "dialCode": "+51", "code": "PE", "iso": "PER", "currency": "PEN"},
    {"name": "Philippines", "dialCode": "+63", "code": "PH", "iso": "PHL", "currency": "PHP"},
    {"name": "Poland", "dialCode": "+48", "code": "PL", "iso": "POL", "currency": "PLN"},
    {"name": "Portugal", "dialCode": "+351", "code": "PT", "iso": "PRT", "currency": "EUR"},
    {"name": "Puerto Rico", "dialCode": "+1", "code": "PR", "iso": "PRI", "currency": "USD"},
    {"name": "Qatar", "dialCode": "+974", "code": "QA", "iso": "QAT", "currency": "QAR"},
    {"name": "Congo", "dialCode": "+242", "code": "CG", "iso": "COG", "currency": "XAF"},
    {"name": "Reunion", "dialCode": "+262", "code": "RE", "iso": "REU", "currency": "EUR"},
    {"name": "Romania", "dialCode": "+40", "code": "RO", "iso": "ROU", "currency": "RON"},
    {"name": "Rwanda", "dialCode": "+250", "code": "RW", "iso": "RWA", "currency": "RWF"},
    {"name": "Saint Barthelemy", "dialCode": "+590", "code": "BL", "iso": "BLM", "currency": "EUR"},
    {"name": "Saint Helena", "dialCode": "+290", "code": "SH", "iso": "SHN", "currency": "SHP"},
    {"name": "Saint Kitts and Nevis", "dialCode": "+1", "code": "KN", "iso": "KNA", "currency": "XCD"},
    {"name": "Saint Martin", "dialCode": "+590", "code": "MF", "iso": "MAF", "currency": "EUR"},
    {"name": "Saint Pierre and Miquelon", "dialCode": "+508", "code": "PM", "iso": "SPM", "currency": "EUR"},
    {"name": "Saint Vincent and the Grenadines", "dialCode": "+1", "code": "VC", "iso": "VCT", "currency": "XCD"},
    {"name": "Samoa", "dialCode": "+685", "code": "WS", "iso": "WSM", "currency": "WST"},
    {"name": "San Marino", "dialCode": "+378", "code": "SM", "iso": "SMR", "currency": "EUR"},
    {"name": "Sao Tome and Principe", "dialCode": "+239", "code": "ST", "iso": "STP", "currency": "STN"},
    {"name": "Saudi Arabia", "dialCode": "+966", "code": "SA", "iso": "SAU", "currency": "SAR"},
    {"name": "Senegal", "dialCode": "+221", "code": "SN", "iso": "SEN", "currency": "XOF"},
    {"name": "Serbia", "dialCode": "+381", "code": "RS", "iso": "SRB", "currency": "RSD"},
    {"name": "Seychelles", "dialCode": "+248", "code": "SC", "iso": "SYC", "currency": "SCR"},
    {"name": "Sierra Leone", "dialCode": "+232", "code": "SL", "iso": "SLE", "currency": "SLL"},
    {"name": "Singapore", "dialCode": "+65", "code": "SG", "iso": "SGP", "currency": "SGD"},
    {"name": "Sint Maarten (Dutch part)", "dialCode": "+1", "code": "SX", "iso": "SXM", "currency": "ANG"},
    {"name": "Slovakia", "dialCode": "+421", "code": "SK", "iso": "SVK", "currency": "EUR"},
    {"name": "Slovenia", "dialCode": "+386", "code": "SI", "iso": "SVN", "currency": "EUR"},
    {"name": "Solomon Islands", "dialCode": "+677", "code": "SB", "iso": "SLB", "currency": "SBD"},
    {"name": "Somalia", "dialCode": "+252", "code": "SO", "iso": "SOM", "currency": "SOS"},
    {"name": "South Africa", "dialCode": "+27", "code": "ZA", "iso": "ZAF", "currency": "ZAR"},
    {"name": "South Korea", "dialCode": "+82", "code": "KR", "iso": "KOR", "currency": "KRW"},
    {"name": "South Sudan", "dialCode": "+211", "code": "SS", "iso": "SSD", "currency": "SSP"},
    {"name": "Spain", "dialCode": "+34", "code": "ES", "iso": "ESP", "currency": "EUR"},
    {"name": "Sri Lanka", "dialCode": "+94", "code": "LK", "iso": "LKA", "currency": "LKR"},
    {"name": "Saint Lucia", "dialCode": "+1", "code": "LC", "iso": "LCA", "currency": "XCD"},
    {"name": "Sudan", "dialCode": "+249", "code": "SD", "iso": "SDN", "currency": "SDG"},
    {"name": "Suriname", "dialCode": "+597", "code": "SR", "iso": "SUR", "currency": "SRD"},
    {"name": "Swaziland", "dialCode": "+268", "code": "SZ", "iso": "SWZ", "currency": "SZL"},
    {"name": "Sweden", "dialCode": "+46", "code": "SE", "iso": "SWE", "currency": "SEK"},
    {"name": "Switzerland", "dialCode": "+41", "code": "CH", "iso": "CHE", "currency": "CHF"},
    {"name": "Syrian Arab Republic", "dialCode": "+963", "code": "SY", "iso": "SYR", "currency": "SYP"},
    {"name": "Taiwan", "dialCode": "+886", "code": "TW", "iso": "TWN", "currency": "TWD"},
    {"name": "Tajikistan", "dialCode": "+992", "code": "TJ", "iso": "TJK", "currency": "TJS"},
    {"name": "Tanzania", "dialCode": "+255", "code": "TZ", "iso": "TZA", "currency": "TZS"},
    {"name": "Thailand", "dialCode": "+66", "code": "TH", "iso": "THA", "currency": "THB"},
    {"name": "Timor-Leste", "dialCode": "+670", "code": "TL", "iso": "TLS", "currency": "USD"},
    {"name": "Togo", "dialCode": "+228", "code": "TG", "iso": "TGO", "currency": "XOF"},
    {"name": "Tokelau", "dialCode": "+690", "code": "TK", "iso": "TKL", "currency": "NZD"},
    {"name": "Tonga", "dialCode": "+676", "code": "TO", "iso": "TON", "currency": "TOP"},
    {"name": "Trinidad and Tobago", "dialCode": "+1", "code": "TT", "iso": "TTO", "currency": "TTD"},
    {"name": "Tunisia", "dialCode": "+216", "code": "TN", "iso": "TUN", "currency": "TND"},
    {"name": "Turkey", "dialCode": "+90", "code": "TR", "iso": "TUR", "currency": "TRY"},
    {"name": "Turkmenistan", "dialCode": "+993", "code": "TM", "iso": "TKM", "currency": "TMT"},
    {"name": "Turks and Caicos Islands", "dialCode": "+1", "code": "TC", "iso": "TCA", "currency": "USD"},
    {"name": "Tuvalu", "dialCode": "+688", "code": "TV", "iso": "TUV", "currency": "AUD"},
    {"name": "Uganda", "dialCode": "+256", "code": "UG", "iso": "UGA", "currency": "UGX"},
    {"name": "Ukraine", "dialCode": "+380", "code": "UA", "iso": "UKR", "currency": "UAH"},
    {"name": "United Arab Emirates", "dialCode": "+971", "code": "AE", "iso": "ARE", "currency": "AED"},
    {"name": "United Kingdom", "dialCode": "+44", "code": "GB", "iso": "GBR", "currency": "GBP"},
    {"name": "Uruguay", "dialCode": "+598", "code": "UY", "iso": "URY", "currency": "UYU"},
    {"name": "U.S. Virgin Islands", "dialCode": "+1", "code": "VI", "iso": "VIR", "currency": "USD"},
    {"name": "Uzbekistan", "dialCode": "+998", "code": "UZ", "iso": "UZB", "currency": "UZS"},
    {"name": "Vanuatu", "dialCode": "+678", "code": "VU", "iso": "VUT", "currency": "VUV"},
    {"name": "Vatican City", "dialCode": "+39", "code": "VA", "iso": "VAT", "currency": "EUR"},
    {"name": "Venezuela", "dialCode": "+58", "code": "VE", "iso": "VEN", "currency": "VED"},
    {"name": "Vietnam", "dialCode": "+84", "code": "VN", "iso": "VNM", "currency": "VND"},
    {"name": "Wallis and Futuna", "dialCode": "+681", "code": "WF", "iso": "WLF", "currency": "XPF"},
    {"name": "Yemen", "dialCode": "+967", "code": "YE", "iso": "YEM", "currency": "YER"},
    {"name": "Zambia", "dialCode": "+260", "code": "ZM", "iso": "ZMB", "currency": "ZMW"},
    {"name": "Zimbabwe", "dialCode": "+263", "code": "ZW", "iso": "ZWE", "currency": "ZWL"}
];

export const dialCodeSet = new Set(allCountries.map(item => item.dialCode));

export const currencyFlagObj = {};
[...allCountries, {currency: "USD", code: "us"}, {currency: "EUR", code: "eu"}].forEach(item => currencyFlagObj[item.currency] = item.code.toLowerCase());

export const countryCodeToNameObj = {};
allCountries.forEach(item => countryCodeToNameObj[item.iso.toLowerCase()] = item.name);