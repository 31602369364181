
const ConditionalRender = ({render, children}) => {

    if (render){
        return children;
    }

    return null
}

export default ConditionalRender;