import React from 'react';
import './scss/index.scss';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import LogRocket from 'logrocket';

const { PUBLIC_URL, REACT_APP_LOG_ROCKET_ID, NODE_ENV } = process.env;

// initialize log rocket
if (NODE_ENV === "production" && REACT_APP_LOG_ROCKET_ID){
    LogRocket.init(REACT_APP_LOG_ROCKET_ID);
}

const root = createRoot(document.getElementById('root'));
root.render(<BrowserRouter basename={PUBLIC_URL} >  
                <App />
            </BrowserRouter>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
