import browserSignature from "browser-signature/dist/storage";
import { deviceDetect, browserName, browserVersion } from "react-device-detect";

/**
 * Intercept all axios request by injecting necessary headers
 * @param {Redux} store redux store 
 * @param {Axios} axios axios instance
 */
const setupAxiosInterceptors = (store, axios) => {

    const activeReq = {};
    // Request Interceptor
    axios.interceptors.request.use(
      config => {
        const {auth: { refreshingToken, token, mode }} = store.getState();
        // Check if to pass token along with the request
        // default to true if not set (pass token if exist and passToken not set)
        const {passToken = true, isTokenReq = false} = config;

        // Prevent Making request when token is refreshing
        if (refreshingToken && passToken && !isTokenReq){
          return Promise.reject({message: "Cancel Request"});
        }
        
        if (token && passToken) {
          config.headers.Authorization = `Bearer ${token}`;
        }

        // Prevent Duplicate Request
        const reqKey = `${config.method}:${config.url}`;
        activeReq[reqKey]?.("Cancel Request");
        config.cancelToken = new axios.CancelToken(function executor(cancel) {
          // An executor function receives a cancel function as a parameter
          activeReq[reqKey] = cancel;
        });

        // Add device info to headers when login or register
        if (config.url === "/user/login" || config.url === "/user/register"){
          // Browser and OS Information
          const deviceInfo = (function(){
                                const device = deviceDetect();
                                const {vendor, model, os, osName, osVersion, browserMajorVersion, ua, userAgent} = device;
                                return JSON.stringify({
                                                        device: `${browserName} ${browserMajorVersion ?? browserVersion} (${osName ?? os} ${osVersion} ${vendor ?? ""} ${model ?? ""})`,
                                                        userAgent: ua ?? userAgent,
                                                        "fingerprint": browserSignature()
                                                      })
                              })();

          config.headers["x-device-info"] = deviceInfo;
        }

        // Check if host is passed
        if (config.host){
          config.url = config.host + config.url;
        }
        else { 
          let host = process.env.REACT_APP_API_HOST;
          host = host?.startsWith("/") ? `${window.location.origin}${host}` : host;
          // Switch host target based on mode
          if (mode === "test" && !host?.includes(".staging.myvendy") && !host?.includes(".sandbox.myvendy")){
            host.replace(".myvendy", ".staging.myvendy")
          }
          config.url = (host || "") + config.url;
        }

        // Override onUploadProgress during test (Not currently supported by the mock server => msw)
        if (process.env.NODE_ENV === "test" && config.onUploadProgress){
          config.onUploadProgress = null;
        }
        return config;
      },
      err => Promise.reject(err)
    );
}

export default setupAxiosInterceptors;