import {createSlice} from "@reduxjs/toolkit";

const initialBalancesState = {
  isLoading: false,
  notification: null,
  banks: null,
  payouts: null,
  sales: {},
  commission: {}
};

export default createSlice({
  name: "balances",
  initialState: initialBalancesState,
  reducers: {
    // Balances Response Notification
    setNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },
    
    // Balances Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },
    
    /*****
     *  Balances Reducers
     */

    // fetchBalance
    balanceFetched: (state, action) => {
      state.isLoading = false;
      state.sales = {...state.sales, ...(action.payload.find(item => item.type === "balance") || {})};
      state.commission = {...state.commission, ...(action.payload.find(item => item.type === "commission") || {})};
    },

    // fetchBanks
    banksFetched: (state, action) => {
      state.isLoading = false;
      state.banks = action.payload || [];
    },

    // fetchPayouts
    payoutsFetched: (state, action) => {
      state.isLoading = false;
      state.payouts = action.payload ?? [];
    },

    // fetchWalletHistory
    walletHistoryFetched: (state, action) => {
      const {data, walletId} = action.payload;
      if (state.sales?.id === walletId){
        state.sales.history = data
      }
      if (state.commission?.id === walletId){
        state.commission.history = data
      }
    },
    

  }
});