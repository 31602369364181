import {createSlice} from "@reduxjs/toolkit";
import { getTransactionStatus } from "app/common/util/Helpers";

const initialTransactionsState = {
  isLoading: false,
  notification: null,
  data: null
};

export default createSlice({
  name: "transactions",
  initialState: initialTransactionsState,
  reducers: {
    // Transactions Response Notification
    setNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },
    
    // Transactions Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },
    
    /*****
     *  Transactions Reducers
     */

    // fetchTransactions
    transactionsFetched: (state, action) => {
      state.isLoading = false;
      state.data = action.payload?.map(item => ({...item, status: getTransactionStatus(item)}));
    },    

  }
});