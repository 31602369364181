import { MAX_FILE_SIZE } from "app/common/data/constants";
import { countryCodeToNameObj, dialCodeSet } from "app/common/data/countries";
import Compressor from 'compressorjs';
import { isMobileOnly } from 'react-device-detect';
import SVG from "react-inlinesvg";
import LogRocket from 'logrocket';
import ConditionalRender from "app/common/components/ConditionalRender";

/**
 * Form Validation Helper
 * @param {form} form form element
 * @param {object} validState Current validation state
 * @returns {object} New Validation State
 */
 export const formValidator = (form, validState) => {
    if (!form) return validState;
    const fieldList = form.querySelectorAll('input, select, textarea');
    const fields = {};
    const fieldsBool = [];

    for (const field of fieldList) {
        // File Mock in test environment
        if (process.env.NODE_ENV === "test" && field.type === "file" && field.files?.length > 0){
            field.required = false;
        }

        const readOnlyType = field.readOnly && field.required;
        if (validState.validated || !!field.value || readOnlyType) {
            fields[field.name] = readOnlyType ? !!field.value : field.checkValidity();
            fieldsBool.push(fields[field.name]);
        }
    }


    const updateState = { ...validState, fields: { ...validState.fields, ...fields } }
    if (!updateState.validated && form.checkValidity() && !fieldsBool.some(opt => !opt)) {
        updateState.validated = true;
    }

    // Confirm all the required fields are satisfied
    updateState.allValid = form.checkValidity();

    return updateState;
}

/**
 * Check Current validity status of the current field
 * @param {object} validObj Current validation object
 * @param {string} fieldName The field 
 * @returns {boolean} valid or not
 */
 export const isValid = (validObj, fieldName) => {

    if (!validObj?.validated) {
        return true;
    }

    if (validObj.fields[fieldName] === true) {
        return true;
    }

    return false;
}


/**
 * Success Notification
 * @param {string} message to display
 * @param {String} action perform
 * @param {object} data passed
 * @param {boolean} clear message after broadcast or not
 * @param {boolean} seen message or not
 * @return {object} notification
 */
export const successNotification = (message, action = null, data = null, statusCode = 200) => {
    return getNotification("success", message, action, data, statusCode);
}

/**
 * Error Notification
 * @param {string} message to display
 * @param {String} action perform
 * @param {object} data passed
 * @param {boolean} showMessage or not
 * @return {object} notification
 */
 export const errorNotification = (message, statusCode = 400, errorCode = null, action = null, data = null, showMessage = true) => {
    return getNotification(showMessage ? "error" : null, message, action, data, statusCode, errorCode);
}

/**
 * Notify Notification
 * @param {String} action perform
 * @param {object} data passed
 * @param {String} statusCode for the notification
 * @return {object} notification
 */
export const notifyAction = (action, data = null, statusCode = 200) => {
    return getNotification(null, null, action, data, statusCode);
}

/**
 * Get Notification
 * @param {string} type (error, success, warn, info) 
 * @param {string} message to display
 * @param {String} action perform
 * @param {object} data passed
 * @param {String} statusCode for the notification
 * @param {String} errorCode for the notification
 * @return {object} notification
 */
export const getNotification = (type, message, action = null, data = null, statusCode = 200, errorCode = null) => {
    return ({notification: {type, message, action, data, statusCode, errorCode}});
}

/**
 * Api Request Error Handler
 * @param {import("redux").Dispatch} dispatch 
 * @param {import("redux").Action} setNotification 
 * @param {String} action perform
 * @param {object} data passed
 * @param {boolean} showMessage or not
 * @returns {VoidFunction}
 */
export const errorHandler = (dispatch, setNotification, action = null, data = null, showMessage = true) => error => {
    if (error?.message && error.message === "Cancel Request" ) return dispatch(setNotification({}));
    const {message, statusCode, errorCode} = errorMessage(error);
    if (process.env.NODE_ENV === "test"){
        // For Test debug purposes
        console.log("Error", {message, statusCode, errorCode})
    }
    dispatch(setNotification({...errorNotification(message, statusCode, errorCode, action, data, showMessage)}));
  }

/**
 * Extract Error Message
 * @param {Object} error message object
 * @return {object} message, errorCode
 */
export const errorMessage = (error) => {

    const statusCode = error?.response?.status ?? 400;

    if (error?.response?.status >= 500){
        console.log({error});
        LogRocket.captureException(error)
        return {statusCode, message: "Unable to process your request at this time. Contact support if error persist."}
    }

    if (error?.response?.status === 0){
        console.log({error})
        return {statusCode, message: "Check your internet connection."}
    }

    let message = "", errorCode = error?.response?.data?.code;
    if (error?.response?.data?.error) {
        message = error.response.data.error;
    }
    else if (error.response?.data?.data?.errors && !Array.isArray(error.response.data.data.errors)) {
        for (let key in error.response.data.data.errors) {
            const val = error.response.data.data.errors[key];
            message += `${Array.isArray(val) ? val[0] : val} `;
        }
    }
    else if (error.response && Array.isArray(error.response?.data?.data)) {
        for (let item of error.response?.data?.data) {
            message += `${Object.values(item || {}).join(", ")} `;
        }
    }
    else if (error.response && typeof (error.response?.data?.message) === "object") {
        for (let key in error.response.data.message) {
            message += `${error.response.data.message[key]} `;
        }
    }
    else if (error.response && typeof (error.response?.data?.msg) === "string") {
        message = error.response.data.msg;
        errorCode = error.response.data.code;
    }
    else if (error.response && typeof (error.response?.data?.message) === "string") {
        message = error.response.data.message;
        errorCode = error.response.data.errorCode;
    }
    else if (Array.isArray(error.data?.errors)) {
        for (const err of error.data.errors) {
            message += `${err.message ?? ""} `;
        }
    }
    else if (error.message) {
        message = error.message;
    }
    else {
        message = `${error}`
    }

    return {statusCode, message, errorCode};
}


// Capitalize first character
export const ucFirst = (s, othersLower = true) => {
    return s && s[0].toUpperCase() + (othersLower ? s.slice(1)?.toLowerCase() : s.slice(1));
}

// Capitalize first character of each words
export const ucWords = (s, othersLower = true) => {
    return s && s.split(/[_ ]/).map(item => ucFirst(item, othersLower)).join(" ");
}


export const convertToNumber = val => {
    const value = val?.toLocaleString("en-US", {maximumFractionDigits: 10})?.replace(/[,]/g, '');
    return parseFloat(value) ? parseFloat(value) : 0;
}

export const formatAmountWithDivision = (currency, amount, decimal = 2, hide = false) => {
    return formatAmount(currency, amount / 100, decimal, hide);
}

export const manageCurrencyInText = txt => {
    return txt?.replace(/US\$/g, "$")?.replace(/NGN/g, "₦");
}
export const formatAmount = (currency, amount, decimal = 2, hide = false) => {
    if (hide){
        return `${getCurrencySign(currency)} *****`;
    }
    try {
        return manageCurrencyInText(`${(convertToNumber(amount) ?? 0).toLocaleString("en-US", { minimumFractionDigits: decimal, maximumFractionDigits: decimal, style: currency ? 'currency': undefined, currency: currency ?? undefined })}`);
    }
    catch(err){
        console.log({err});
        return manageCurrencyInText(`${currency}${(convertToNumber(amount) ?? 0).toLocaleString("en-US", { minimumFractionDigits: decimal, maximumFractionDigits: decimal })}`);
    }
}

export const getCurrencySign = currency => {
    try {
        return manageCurrencyInText(`${(0).toLocaleString("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 0, style: currency ? 'currency': undefined, currency: currency ?? undefined })}`)?.replace("0", "");
    }
    catch(err){
        console.log({err});
        return manageCurrencyInText(currency);
    }
}

export const getCurrencyName = currency => {
    const obj = {"NGN": "Naira", "USD": "Dollar", "GBP": "Pound", "EUR": "Euro"};
    return obj[currency?.toUpperCase()] ?? currency;
}

export const convertUTCToLocalDate = datetime => {
    if (datetime?.toLocaleString().trim().length !== 10) {
        if (datetime?.toLocaleString().trim().length >= 22) {
            return new Date(datetime);
        }
        const curDate = new Date();
        datetime = (new Date(datetime)).getTime() - (curDate.getTimezoneOffset() * 60000);
    }
    else if (datetime?.trim().length === 10) {
        return new Date(`${datetime} 00:00:00`)
    }
    return datetime ? new Date(datetime) : null
}


export const formatDayMonth = datetime => {
    const event = new Date(convertUTCToLocalDate(datetime));
    const options = {  month: 'long',  day: "2-digit" };
    return datetime ? event.toLocaleDateString('en-GB', options)?.replace(' ', ', ') : "N/A";
}

export const formatDayMonthYear = (datetime, format = "en-US", month = "short") => {
    const event = new Date(convertUTCToLocalDate(datetime));
    const options = {  month,  day: "2-digit", year: 'numeric' };
    return datetime ? event.toLocaleDateString(format, options) : "N/A";
}

export const formatDayMonthYearWithSlash = datetime => {
    return formatDayMonthYear(datetime)?.replace(/,/g, "")?.replace(/ /g, " / ");
}


export const getStatusClass = status => {
    const statusObj = { pending: "pending-status", processing: "warn-status", "under review": "warn-status", uploaded: "success-status", success: "success-status", approved: "success-status", updated: "success-status", rejected: "error-status", empty: "error-status", "not uploaded": "error-status", "require update": "error-status", verified: "success-status",  };
    return status ? statusObj[status.toLowerCase()] : null;
}


export const formatDayMonthTime = (datetime, format = "en-US", month = "short") => {
    const event = new Date(convertUTCToLocalDate(datetime));
    const options = {  month,  day: "2-digit", hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
    return datetime ? event.toLocaleDateString(format, options) : "N/A";
}

export const formatDate = datetime => {
    const event = new Date(convertUTCToLocalDate(datetime));
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true };
    return datetime ? event.toLocaleDateString('en-US', options) : "N/A";
}

/**
 * Get Time Passed
 * @param {string} dt date 
 * @returns {string} elapsed time
 */
 export const getElapseTime = (dt, day = true) => {

    if (!dt) return null;

    const now = new Date();
    const otherDate = new Date(formatDate(dt));

    const diff = now.getTime() - otherDate.getTime();
    const msec = diff;

    // Years
    const yy = Math.floor(msec / 1000 / 60 / 60 / 24 / 30 / 12);
    if (yy > 0) {
        return `${yy} year${yy > 1 ? "s" : ""}`;
    }
    // Months
    const mn = Math.floor(msec / 1000 / 60 / 60 / 24 / 30);
    if (mn > 0) {
        return `${mn} month${mn > 1 ? "s" : ""}`;
    }
    // Days
    const dd = Math.floor(msec / 1000 / 60 / 60 / 24);
    if (dd > 0) {
        return `${dd} day${dd > 1 ? "s" : ""}`;
    }

    if (day){
        return "1 day"
    }
    
    // Hours
    const hh = Math.floor(msec / 1000 / 60 / 60);
    if (hh > 0) {
        return `${hh} hour${hh > 1 ? "s" : ""}`;
    }
    // Minutes
    const mm = Math.floor(msec / 1000 / 60);
    if (mm > 0) {
        return `${mm} minute${mm > 1 ? "s" : ""}`;
    }

    return "Just now";
}

/**
 * Get Time Passed
 * @param {string} dt date 
 * @returns {string} elapsed time
 */
 export const getDayElapseHr = (dt, day = true) => {

    if (!dt) return null;

    const now = new Date();
    const otherDate = new Date(formatDate(dt));

    const diff = now.getTime() - otherDate.getTime();
    const msec = diff;
    
    // Hours
    const hh = 24 - (Math.floor(msec / 1000 / 60 / 60));
    if (hh > 0) {
        return `${hh} hr${hh > 1 ? "s" : ""}`;
    }

    return "Few min";
}

/**
 * Delay Execution logic
 * @param {function} func to execute after delay
 * @param {integer} delay in ms
 * @returns {void}
 */
export const debounce = function (func, delay) {
    let timeout;
    return function () {
        const context = this, args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(_ => func.apply(context, args), delay);
    }
}

/**
 * Generate Random string
 * @returns {string}
 */
 export const randomString = _ => {
    return (Math.random() + 1).toString(36).substring(2);
}

/**
 * Decode JWT Token
 * @param {string} token 
 * @returns {JSON}
 */
export const parseJWT = token => {
    try{
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                            }).join(''));

        return JSON.parse(jsonPayload);
    }
    catch (e){
        console.log(e.message)
        return {};
    }
};

/**
 * Extract Data From Form Html Elements
 * @param {Form} form 
 * @returns {object}
 */
export const getFormElementData = form => {
    return form ? getFormData(new FormData(form)) : {};
}


/**
 * Extract data from form data
 * @param {FormData} formObj 
 * @returns  {object}
 */
export const getFormData = formObj => {
    const data = Object.fromEntries(formObj.entries());
    for (let key in  data){
        data[key] = data[key]?.trim?.();
        // Remove field with empty data
        if (!data[key]){
            data[key] = undefined;
        }
        if ((key?.toLowerCase().includes("start") || key?.toLowerCase().includes("end")) && data[key]?.includes("-")){
            // Reformat date
            const valArr = data[key]?.split("-");
            if (valArr && valArr[2] > 31){
                data[key] = `${valArr[2]}-${valArr[1]}-${valArr[0]}`;
            }
        }
        if (key.includes("phone") || key.includes("msisdn")){
            data[key] = data[key] ? `${data["countryCode"]}${data[key]}`?.replace(/[ +]/g, ""): undefined;
        }
        if (key === "amount" || key === "price" || key === "from" || key === "to" || key === "quantity"){
            data[key] = data[key]?.replace(/,/g, "");
        }
        // Remove space in account Number or Iban
        if (key.includes("iban") || key.includes("accountNumber")){
            data[key] = data[key]?.replace(/ /g, "");
        }

        // Strip RC or BN from registration Number
        if ((key === "rcNumber" || key === "registrationNumber") && ["RC", "BN"].includes(data[key]?.substr(0, 2)?.toUpperCase())){
            data[key] = data[key].substr(2);
        }

        // Deep Object Data
        if (key.includes(".")){
            const keys = key.split(".");
            let propertyName = keys.pop();
            let propertyParent = data;
            while (keys.length > 0) {
                const nextKey = keys.shift();
                if (!propertyParent[nextKey]){
                    propertyParent[nextKey] = {};
                }
                propertyParent = propertyParent[nextKey];
            }
            propertyParent[propertyName] = data[key];
            delete data[key];
        }
    }

    delete data["countryCode"];
    return data;
}

/**
 * Truncate long String
 * @param {string} str of news 
 * @returns truncated version
 */
 export const truncateString = (str, len = 12) => {
    if (!str) return null;
    return `${str?.substr(0, len)}${str.length > len ? ".." : ""}`;
}

/**
 * Convert base64 file to file object
 * @param {string} dataUrl base64 image 
 * @param {*} filename name
 */
 export const dataURLtoFile = (dataUrl, filename) => {
    let arr = dataUrl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}

/**
 * Update Current State 
 * @param {func} state 
 * @param {object} newData 
 */
export const update = (state, newData) => {
    state(currData => ({...currData, ...(newData ?? {})}));
}

/**
 *  Get Current Theme
 * @returns {string}
 */
export const getTheme = _ => {
    if (localStorage.getItem("theme") === 'dark' || (!('theme' in localStorage) && window.matchMedia?.('(prefers-color-scheme: dark)').matches)) {
        return "dark"
    }
    return "light";
}

/**
 * Form File to blob
 * @param {File} file 
 * @returns 
 */
export const fileToBlob = file => new Promise((res, rej) => {
    const fr = new FileReader()
    fr.readAsArrayBuffer(file);
    fr.onload = _ => res(new Blob([fr.result]));
});

/**
 * Convert Heic/Heif to png
 * @param {File} file 
 * @returns 
 */
export const convertHeicHeifToPNG = async file => {
    const blob = await fileToBlob(file);
    const conversionBlob = await window.heic2any({ blob, toType: "image/png", quality: 1  });
    return new File([conversionBlob], `${file.name?.split('.').slice(0, -1).join('.')}.png`, {type:"image/png", lastModified:new Date().getTime()})
} 

/**
 * Image Compression
 * @param {File} file 
 * @returns File
 */
export const compressImage = file => new Promise((res, rej) => {
    new Compressor(file, { quality: 0.6, convertSize: MAX_FILE_SIZE, success: res, error: rej})
});

/**
 * Process Images in the form data
 * @param {Form} formData 
 * @returns 
 */
export const processFormImages = async formData => {
    // Disable in test
    if (process.env.NODE_ENV === "test") {
        return formData;
    }
    for (let [key, val] of formData.entries()) {
        const type = val?.type?.toLowerCase();
        if (type?.startsWith("image/")){
            // Image Processing
            let processImg;
            if (type === "image/heic" || type === "image/heif"){
                // Heic/Heif Image Converter
                processImg = await convertHeicHeifToPNG(val);
                formData.set(key, processImg);
            }
            // Compress Image
            processImg = await compressImage(formData.get(key));
            formData.set(key, processImg, processImg.name);

            if (processImg.size > MAX_FILE_SIZE){
                throw new Error(`${processImg.name} file too large (1Mb Max)`)
            }
        }
    }
    
    return formData;
}

/**
 * Other Non generic bank information
 * @param {object} bank 
 * @returns 
 */
export const getOtherBankDetails = bank => {
  const knownFields = new Set(["name", "address", "swiftCode", "routingNumber"]);
  const otherFields = [];
  for (let key in bank){
    if (!knownFields.has(key) && bank[key]){
        otherFields.push({
            title: ucWords(key),
            value: bank[key],
            colclass: "sm:w-full"
        })
    }
  }
  return otherFields;
}

/**
 * View or Download document from form file
 * @param {File} file 
 * @param {boolean} download 
 */
export const viewFormFileDoc = (file, download = false) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = _ => {
        viewBase64Doc(reader.result, file.name, download);
    };
    reader.onerror = console.log;
}

/**
 * View or Download document from base64 Data Url
 * @param {string} dataUrl 
 * @param {string} name 
 * @param {boolean} download 
 */
export const viewBase64Doc = (dataUrl, name, download = false) => {
    const link = document.createElement('a');
    link.href = dataUrl;
    if (download || isMobileOnly){
      link.setAttribute('download', name);
    }
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

/**
 * Change User Input to uppercase as they type
 * @param {object} e 
 */
export const inputToUpperCase = e => {
    e.currentTarget.value = e.currentTarget.value?.toUpperCase() ?? "";
}

/**
 * Confirm if anything change
 * @param {object} currData 
 * @param {object} newData 
 * @returns {boolean}
 */
export const isUpdateBeneficiary = (currData, newData) => {
    const keys = ["name", "country", "city", "countryCode", "phone", "email", "street", "postCode"];
    for (const key of keys){
        if (currData[key]?.toLowerCase() !== newData[key]?.toLowerCase()){
            console.log({key, current: currData[key], new: newData[key] })
            return true;
        }
    }
    return false;
}



/**
 * Copy Text to Clip Board
 * @param {string} text 
 */
 export const copyToClipBoard = (text, toastMessage = null) => {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    toastMessage?.("info", "Copied to Clipboard");
}

/**
 * Number Count Indicator Formatting
 * @param {count} numb 
 * @param {boolean} hundred if to consider
 * @returns formatted output
 */
export const countManager = (numb, hundred = false) => {
    if (!numb || numb < (hundred ? 100 : 1000)) return numb || 0;
    if (numb < 1000) return `${Math.floor(numb/10)/10}H`;
    if (numb < 1000000) return `${Math.floor(numb/100)/10}K`;
    if (numb < 1000000000) return `${Math.floor(numb/100000)/10}M`;
    if (numb < 1000000000000) return `${Math.floor(numb/100000000)/10}B`;
    return `${Math.floor(numb/100000000000)/10}T`;
} 

/**
 * Get Time Passed
 * @param {string} dt date 
 * @returns {string} elapsed time
 */
export const getLastActive = dt => {

    if (!dt) return null;

    const now = new Date();
    const otherDate = new Date(formatDate(dt));

    const diff = now.getTime() - otherDate.getTime();
    const msec = diff;

    // Years
    const yy = Math.floor(msec / 1000 / 60 / 60 / 24 / 30 / 12);
    if (yy > 0) {
        return `Last login ${yy} year${yy > 1 ? "s" : ""} at ${formatTime(dt)}`;
    }
    // Months
    const mn = Math.floor(msec / 1000 / 60 / 60 / 24 / 30);
    if (mn > 0) {
        return `Last login ${mn} month${mn > 1 ? "s" : ""} at ${formatTime(dt)}`;
    }
    // Days
    const dd = Math.floor(msec / 1000 / 60 / 60 / 24);
    if (dd > 0) {
        return `Last login ${dd} day${dd > 1 ? "s" : ""} at ${formatTime(dt)}`;
    }
    // Hours
    const hh = Math.floor(msec / 1000 / 60 / 60);
    if (hh > 0) {
        return `Last login ${hh} hour${hh > 1 ? "s" : ""} at ${formatTime(dt)}`;
    }

    return <span className='text-success'>Active Now</span>;
}

export const formatTime = datetime => {
    const event = new Date(convertUTCToLocalDate(datetime));
    const options = { hour: 'numeric', minute: 'numeric', second: "numeric", hour12: true };
    return datetime ? event.toLocaleTimeString('en-US', options) : "N/A";
}

/**
 * Decode encoded html
 * @param {string} str encoded html 
 * @returns decoded html
 */
export const decodeHTML = str => {
    const txt = document.createElement("textarea");
    txt.innerHTML = str;
    return txt.value;
}

/**
 * Get date in iso format
 * @param {string} d date to parse
 * @returns YYYY-MM-DD HH:MM:SS
 */
export const getIsoDate = d => {
    const dt = new Date(convertUTCToLocalDate(d ?? Date.now()));
    return dt.toISOString();
}

/**
 * Mask part of a string
 * @param {string} str 
 * @param {boolean} masked show / mask
 * @param {integer} last 
 * @returns {string}
 */
export const maskString = (str, masked = true, last = 6) => {
    if (!str){
        return "*".repeat(10);
    }
    if (masked === true){
        return `${"*".repeat(Math.abs(str.length - last))}${str.substr(-last)}`;
    }
    return str;
}

/**
 * Code to Country Name
 * @param {string} isoCode 
 * @returns 
 */
export const getCountryName = isoCode => {
  return countryCodeToNameObj[isoCode?.toLowerCase()] ?? "";
}

/**
 * Extract address details from object
 * @param {object} address 
 * @returns {string}
 */
 export const getAddressFromObj = address => {
    return  `${address?.street ?? "--"}, ${address?.city ?? ""} ${address?.state ?? ""} ${address?.postCode ?? ""} ${getCountryName(address?.country)}`
}

/**
 * Separate Phone Number to country code and phone where applicable
 * @param {string} phone Number with code
 * @param {string} defaultCountryCode if not set
 * @returns 
 */
export const separatePhoneAndDialCode = (phoneNumber, defaultCountryCode = "+234") => {

  const obj = {countryCode: defaultCountryCode, phone: null}
  if (!phoneNumber){
      return obj;
  }
  if (phoneNumber[0] !== "+"){
    phoneNumber = "+" + phoneNumber;
  }
  if (phoneNumber.length > 4){
      if (dialCodeSet.has(phoneNumber.substr(0, 4))){
          return {countryCode: phoneNumber.substr(0, 4), phone: phoneNumber.substr(4)}
      }
      if (dialCodeSet.has(phoneNumber.substr(0, 3))){
          return {countryCode: phoneNumber.substr(0, 3), phone: phoneNumber.substr(3)}
      }
      if (dialCodeSet.has(phoneNumber.substr(0, 2))){
          return {countryCode: phoneNumber.substr(0, 2), phone: phoneNumber.substr(2)}
      }
  }

  return obj;
}


/**
 * Extract text between two string
 * @param {string} str 
 * @param {string} start 
 * @param {string} end 
 * @returns 
 */
export const extractTextBetween = (str, start, end) => {
    return str?.split(start)?.[1]?.split(end)?.[0] ?? "";
}

/**
 * Set or Clear state Data 
 * @param {func} state 
 * @param {object} newData 
 */
 export const showOrHide = (state, newData, key = "id") => {
    state?.(currData => ((currData?.[key] && currData?.[key] === newData?.[key])  || currData === newData) ? null : newData);
}

/**
 * Get Transaction Status
 * @param {object} data 
 * @returns {string}
 */
export const getTransactionStatus = data => {
    if (data?.processing === 1) return "processing";
    if (data?.isreversing === 1) return "reversing";
    if (data?.reversed === 1) return "reversed";
    if (data?.failed === 1) return "failed";
    if (data?.failed === 3) return "cancelled";
    if (data?.failed === 0) return "completed";
    return "processing";
}

/**
 * Pass status value
 * @param {string} status 
 * @param {string} className additional
 * @returns 
 */
export const formatStatus = (status, className, iconFirst = false) => {
    const statusPeers = {declined: "failed", cancelled: "failed", "awaiting feedback": "processing", pending: "processing", "default payout": "succeeded", joined: "succeeded", reversing: "reversed" };
    const statusCat = statusPeers[status?.toLowerCase()] || status;
    const color = { completed: "text-success", succeeded: "text-success", active: "text-success", failed: "text-danger", processing: "text-grey-6", reversed: "text-grey-6", disabled: "text-grey-6", ongoing: "text-ongoing" };
    const bg = { completed: "bg-success-1", succeeded: "bg-success-light", active: "bg-success-light", failed: "bg-danger-light", processing: "bg-grey-1", reversed: "bg-grey-1", disabled: "bg-grey-1", ongoing: "bg-ongoing-light"};
    return status ? (<span className={`py-1.5 px-2.5 inline-block font-medium rounded whitespace-nowrap ${color[statusCat?.toLowerCase()]} ${bg[statusCat?.toLowerCase()]} ${className}`}>
                        <ConditionalRender
                            render={iconFirst}
                        >
                            <SVG
                                src={`/assets/media/svg/status/${statusCat}.svg`}
                                className="w-4 mr-1.5 inline-block -mt-1"
                            />
                        </ConditionalRender>
                        {ucWords(status?.replace(/_/g, " "))}
                        <ConditionalRender
                            render={!iconFirst}
                        >
                            <SVG
                                src={`/assets/media/svg/status/${statusCat}.svg`}
                                className="w-4 ml-2.5 inline-block -mt-1"
                            />
                        </ConditionalRender>
                    </span>) : null;
}

/**
 * Extract status and format it afterward
 * @param {*} data 
 * @returns {string}
 */
export const getAndFormatStatus = data => {
    return formatStatus(getTransactionStatus(data));
}