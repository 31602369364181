import {createSlice} from "@reduxjs/toolkit";

const initialDashboardState = {
  isLoading: false,
  notification: null,
  salesPerformance: null,
  revenueSummary: null
};

export default createSlice({
  name: "dashboard",
  initialState: initialDashboardState,
  reducers: {
    // Dashboard Response Notification
    setNotification: (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
    },
    
    // Dashboard Loading
    isLoading: (state, _) => {
      state.isLoading = true;
      state.notification = null
    },
    
    /*****
     *  Dashboard Reducers
     */

    // fetchSalesPerformance
    salesPerformanceFetched: (state, action) => {
      state.isLoading = false; 
      state.salesPerformance = action.payload;
    },

    // fetchRevenueSummary
    revenueSummaryFetched: (state, action) => {
      state.isLoading = false; 
      state.revenueSummary = action.payload;
    },
  

  }
});

