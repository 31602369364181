import { StrictMode, Suspense } from "react"
import { Provider } from "react-redux";
import LayoutSplashScreen, { SplashScreenProvider } from "./common/layout/_components/LayoutSplashScreen";
import store, { persistor } from "./redux/store";
import Routes from "./routes";
import { PersistGate } from 'redux-persist/integration/react';
import setupAxiosInterceptors from "./redux/setupAxiosInterceptors";
import axios from "axios";
import { IntercomProvider } from "react-use-intercom";

/**
 * Inject interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxiosInterceptors(store, axios)

const App = _ => {
  return  <StrictMode>
            <SplashScreenProvider>
              {/* Provide Redux store  */}
              <Provider store={store}>
                {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
                <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
                  <Suspense fallback={<LayoutSplashScreen />}>
                    <IntercomProvider appId={process.env.REACT_APP_INTERCOM_ID}>
                      <Routes/>
                    </IntercomProvider>
                  </Suspense>
                </PersistGate>
              </Provider>
            </SplashScreenProvider>
          </StrictMode>
}

export default App;
