import {configureStore} from "@reduxjs/toolkit";
import {rootReducer} from "./rootReducer";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER} from 'redux-persist'
import localStorage from 'redux-persist/lib/storage';


// Redux persistor

const persistConfig = {
  key: 'vendy-app',
  version: 1,
  storage: localStorage,
  whitelist: ["auth"] // only auth will be persisted
}

const persistedReducer = persistReducer(persistConfig, rootReducer)


const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
                                                              serializableCheck: {
                                                                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                                                              },
                                                            })
});

export const persistor = persistStore(store);


export default store;
