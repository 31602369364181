import { createAction } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/auth/authHandler";
import { ucFirst } from "../util/Helpers";
import toastMessage from "../util/toastMessage";

const NotificationWatcher = _ => {

    const dispatch = useDispatch();
    const notifications = useSelector(state => {
                                                    // Extract All notifications from global state
                                                    const notifications = {}
                                                    for (const key in state) {
                                                        if (state[key]?.notification){
                                                            notifications[key] = state[key]?.notification
                                                        }
                                                    }
                                                    return notifications;
                                            }, shallowEqual);

    useEffect(_ => {
        // Check if there is any new notification to display in notifications list
        for (const key in notifications) {
            const notification = notifications[key]; 
            // Log User Out for Unauthorized Access
            if (401 === notification.statusCode && notification.action !== "allow401"){
                console.log({notification});
                toastMessage("info", "Unauthorized Access");
                dispatch(logout());
                return;
            }
            else if (notification.type){
                toastMessage(notification.type, ucFirst(notification.message, false));
            }
            
            // Notification clean up
            const action = createAction(`${key}/setNotification`);
            dispatch(action({notification: null}));  
        }
    }, [dispatch, notifications]);

    return null;
}

export default NotificationWatcher;